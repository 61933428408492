<script setup>
import VerificationInput from "@/components/input/verification/VerificationInput.vue";
import VueTypes from "vue-types";
import { computed, ref, watch } from "vue";

const props = defineProps({
  visible: VueTypes.bool.def(false),
  loading: VueTypes.bool.def(false),
  text: VueTypes.string,
  value: VueTypes.string.def(""),
  title: VueTypes.string,
  disableOkIsNotFill: VueTypes.bool.def(true),
  modal: VueTypes.object.def({
    ok: "Onayla",
    cancel: "İptal",
  }),
});
const emit = defineEmits([
  "is:ok",
  "is:complete",
  "is:cancel",
  "update:loading",
]);

const code = ref("");
const loading = computed({
  get: () => props.loading,
  set: (v) => emit("update:loading", v),
});
const verificationInput = ref(null);

const onComplete = (val) => {
  code.value = val;
  emit("is:complete", val);
};

const onOkModal = () => {
  if (code.value === "") {
    emit("is:ok", { code: null, isTrust: false });
  } else {
    emit("is:ok", { code: code.value, isTrust: true });
  }
};

const onCancelModal = () => {
  emit("is:cancel");
};

const showLoading = () => {
  loading.value = true;
};

const closeLoading = () => {
  loading.value = false;
};

const restart = () => {
  if (verificationInput.value) {
    code.value = "";
    verificationInput.value.restartCode();
  }
};

const focus = () => {
  if (verificationInput.value) {
    verificationInput.value.focusFirstInput();
  }
};

watch(
  () => props.visible,
  (newVal) => {
    if (newVal) {
      focus();
    }
  }
);

defineExpose({
  restart,
  showLoading,
  closeLoading,
});
</script>

<template>
  <div v-if="visible">
    <a-modal
      :visible="visible"
      :title="title"
      :ok-text="modal.ok"
      :cancel-text="modal.cancel"
      @ok="onOkModal"
      @cancel="onCancelModal"
      :ok-button-props="{
        disabled: disableOkIsNotFill && code === '',
      }"
      :confirm-loading="loading"
      class="verification-modal base-form"
    >
      <p v-if="text" v-html="text" class="is-description"></p>
      <verification-input
        ref="verificationInput"
        @is:complete="onComplete"
      ></verification-input>
    </a-modal>
  </div>
</template>

<style lang="scss">
.verification-modal {
  .ant-modal-footer {
    .ant-btn-primary {
      &:disabled {
        color: var(--theme-primary-color);
        opacity: 0.7;
      }
    }
  }
}
</style>
