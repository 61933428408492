<script setup>
import VueTypes from "vue-types";
import { ref, onMounted } from "vue";

const props = defineProps({
  length: VueTypes.number.def(6),
});

const emit = defineEmits(["is:complete"]);

const code = ref(Array(props.length).fill(""));
const inputs = ref({});

const getKeyByIndex = (index) => "input-" + index;

const addRef = (_ref, index) => {
  const key = getKeyByIndex(index);
  inputs.value[key] = _ref;
};

const onComplete = (code) => {
  emit("is:complete", code);
};

const onValueChange = (val, index) => {
  code.value[index] = val;
  if (index !== props.length - 1) {
    focusInput(index + 1);
  }
  if (code.value.every((n) => n !== "")) {
    onComplete(code.value.join(""));
  }
};

const onKeyUp = (event, index) => {
  if (
    event.keyCode === 8 &&
    typeof code.value[index] !== "number" &&
    index !== 0
  ) {
    const newCode = [...code.value];
    newCode[index - 1] = "";
    code.value = newCode;
    focusInput(index - 1);
  }
};

const focusInput = (index) => {
  const key = getKeyByIndex(index);
  if (inputs.value[key]) {
    inputs.value[key].blur();
    inputs.value[key].focus();
  }
};

const restartCode = () => {
  code.value = Array(props.length).fill("");
  focusInput(0);
};

const focusFirstInput = () => {
  focusInput(0);
};

const onPaste = (e) => {
  e.preventDefault();
  const res = (e.clipboardData || window.clipboardData).getData("text");
  if (res.split("").every((e) => isNaN(+e))) return;
  const newCode = res.split("").map((n) => +n);
  if (newCode.length === props.length) {
    code.value = newCode;
    focusInput(props.length - 1);
    onComplete(code.value.join(""));
  }
};

defineExpose({
  restartCode,
  focusFirstInput,
});

onMounted(() => {
  focusInput(0);
});
</script>

<template>
  <a-space class="verification-input-space">
    <template v-for="(number, index) in code" :key="index">
      <a-input-number
        :min="0"
        :max="9"
        :value="number"
        :controls="false"
        :ref="(ref) => addRef(ref, index)"
        @change="(v) => onValueChange(v, index)"
        @keyup="(e) => onKeyUp(e, index)"
        v-is-mask="'#'"
        autocomplete="one-time-code"
        @paste="onPaste"
      ></a-input-number>
    </template>
  </a-space>
</template>

<style lang="scss">
.verification-input-space {
  flex-wrap: nowrap !important;
  .ant-input-number {
    width: auto !important;
    min-height: 40px !important;
    height: auto !important;
    display: flex !important;
    align-items: center !important;
    input {
      text-align: center !important;
    }
  }
}
</style>
