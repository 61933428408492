import {defineStore} from 'pinia';
import {debounce} from '@/utility/utility';
import {useUserStore} from './user.store';

export const useChatStore = defineStore('chat', {
	state: () => ({
		message: '',
		messages: [],
		isNextPage: false,
		current: {},
		lastMessageDate: '',
		note: {message: null},
		activeNote: null,
		status: '',
		typing: false,
		temp: {},
		error: {
			word: null,
		},
	}),
	getters: {
		getCurrentChat: (state) => state.current,
		getMessages: (state) => state.messages,
		getIsNextPage: (state) => state.isNextPage,
		getLastMessageDate: (state) => state.lastMessageDate,
		getChatStatus: (state) => state.status,
		getNote: (state) => state.note,
		getActiveNote: (state) => state.activeNote,
		getTyping: (state) => state.typing,
		getError: (state) => state.error,
		getMessage: (state) => state.message,
	},
	actions: {
		setMessage(message) {
			this.message = message;
		},
		setTemp(temp) {
			this.temp = temp;
		},
		createFromTemp() {
			if (this.temp && Object.keys(this.temp).length > 0) {
				this.current = {...this.temp};
			}
		},
		archiveChat({chatID}) {
			if (this.current && this.current.chatID === chatID) {
				const userStore = useUserStore();
				this.current.archivers.push(userStore.getUserId);
			}
		},
		unarchiveChat({chatID}) {
			if (this.current && this.current.chatID === chatID) {
				const userStore = useUserStore();
				const id = userStore.getUserId;
				this.current.archivers = this.current.archivers.filter((u) => u !== id);
			}
		},
		setActiveNote(note) {
			this.activeNote = note;
		},
		setLastMessageDate(date) {
			this.lastMessageDate = date;
		},
		setCurrentChat(chat) {
			this.current = chat;
			this.temp = {};
		},
		setChatMessages(messages) {
			this.messages = [...messages].reverse();
		},
		addChatMessages(messages) {
			console.log('fasdfsdaf', messages);
			this.messages = [...this.messages, ...[...messages].reverse()];
		},
		setIsNextPage(isNextPage) {
			this.isNextPage = isNextPage;
		},
		setActiveChatOnlineStatus(status) {
			this.status = status;
		},
		setActiveChatNote(data) {
			this.note = data;
			if (data && data.message) {
				this.setActiveNote(data && data.message ? data.message : '');
			}
		},
		receiveMessage({chatId, message, senderName, avatar, senderId}) {
			if (this.current.chatID === chatId) {
				const userStore = useUserStore();
				const userId = userStore.getUserId;
				this.messages.push({
					Message: message,
					System: false,
					chatID: chatId,
					isDeleted: false,
					receiverID: userId,
					senderID: senderId,
					Datetime: new Date(),
				});
			}
		},
		addMessage({chatId, message, receiverId, userId, date}) {
			console.log('kac kere calisti');
			if (this.current.chatID === chatId) {
				this.messages.push({
					Message: message,
					System: false,
					chatID: chatId,
					isDeleted: false,
					receiverID: receiverId,
					senderID: userId,
					Datetime: date,
				});
			}
		},
		debouncedRemoveTyping: debounce((func) => {
			func();
		}, 1500),
		setValidationError(error) {
			this.error = error;
		},
		resetValidationError() {
			this.error = {word: null};
		},
		addTypingChat({chatId}) {
			if (chatId === this.current.chatID) {
				this.typing = true;
				this.debouncedRemoveTyping(this.removeTyping);
			}
		},
		removeTyping() {
			this.typing = false;
		},
		clear() {
			this.$reset();
		},
	},
});
