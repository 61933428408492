import axios from "axios";

export const http = axios.create({
  baseURL: "https://www.itemsatis.com/api",
  withCredentials: true,
  timeout: 50000,
});

http.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
http.defaults.headers.common["Access-Control-Allow-Methods"] =
  "GET,PUT,POST,DELETE,PATCH,OPTIONS";

http.defaults.headers.common["Access-Control-Allow-Headers"] =
  "Content-Type, Authorization, X-Requested-With";

const https = axios.create({
  baseURL: "https://www.itemsatis.com/api",
  withCredentials: true,
  timeout: 50000,
});

https.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    if (!token) return config;
    if (config.data === undefined) {
      config.data = `token=${token}`;
      return config;
    }
    if (typeof config.data === "string") {
      config.data = config.data + `&token=${token}`;
      return config;
    }
    if (config.data instanceof FormData) {
      config.data.append("token", token);
      return config;
    }
    return config;
  },
  function (err) {
    return Promise.reject({
      fail: true,
      success: false,
      redirect: false,
      vpnPossible: false,
      ...err,
    });
  }
);

export const useHttps = () => https;
