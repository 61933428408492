/* eslint-disable no-console */
import { register } from "register-service-worker";

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {},
    registered() {},
    cached() {},
    updatefound() {},
    updated() {
      //console.log("New content is available; please refresh.");
    },
    offline() {},
    error(error) {},
  });
  register(`${process.env.BASE_URL}itemsatis-worker.js`);
  /*
  * register(`${process.env.BASE_URL}messaging_init_in_sw.js`, {
    registrationOptions: {
      type: "module",
    },
  });
  register(`${process.env.BASE_URL}messaging_on_background_message.js`, {
    registrationOptions: {
      type: "module",
    },
  });
  * */
  register(`${process.env.BASE_URL}firebase-messaging-sw.js`);

  /*register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
    ready () {
      console.log("firebase ready")
    },
    registered () {
      console.log('firebase Service worker has been registered.')
    },
    cached () {
      console.log('firebase Content has been cached for offline use.')
    },
    updatefound () {
      console.log('firebase New content is downloading.')
    },
    updated () {
      console.log('firebase New content is available; please refresh.')
    },
    offline () {
      console.log('firebase No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('firebase Error during service worker registration:', error)
    }
  })*/
}
