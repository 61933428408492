const createEventService = ({
	userStore,
	chatStore,
	chatListStore,
	socketService,
}) => {
	return {
		receiveUnarchivedChats({chats, page}) {
			chatListStore.setUnarchivedChats({chats, page});
		},
		receiveArchivedChats({chats, page}) {
			chatListStore.setArchivedChats({chats, page});
		},
		// receiveMessageDetails(messages) {
		// 	if (messages && messages.length > 1) {
		// 		chatStore.setLastMessageDate(messages[messages.length - 1].Datetime);
		// 	}
		// 	chatStore.setChatMessages(messages);
		// },
		onMsgValidationError(params) {
			chatStore.setValidationError(params);
		},
		receiveChatDetail(details) {
			if (details === null) {
				return chatStore.createFromTemp();
			} // ? not found chat
			chatStore.setCurrentChat(details);
			socketService.emit('getMessageDetails', details.chatID);
			const username =
				+details.senderID === +userStore.getUserId
					? details.receiverName
					: details.senderName;
			this.getUserOnlineStatus({
				username: username,
			});
			this.seenMessages({
				chatId: details.chatID,
				username: username,
			});
		},
		receiveUserOnlineStatus(status) {
			chatStore.setActiveChatOnlineStatus(status);
		},
		receiveMessageOlder(chats) {
			if (chats && chats.length > 1) {
				chatStore.setLastMessageDate(chats[chats.length - 1].Datetime);
			}
			chatStore.addChatMessages(chats);
		},
		receiveSendNoteResult({data}) {
			chatStore.setActiveChatNote(data);
		},
		receiveGetNoteResult({data}) {
			chatStore.setActiveChatNote(data);
		},
		archiveChatResult(params) {
			if (params && params.chatID) {
				chatStore.archiveChat(params);
				chatListStore.archiveChat(params);
			}
		},
		unarchiveChatResult(params) {
			if (params && params.chatID) {
				chatStore.unarchiveChat(params);
				chatListStore.unarchiveChat(params);
			}
		},
		onTyping(chatId) {
			chatListStore.addTypingChat({chatId: chatId});
			chatStore.addTypingChat({chatId: chatId});
		},
		getAllChats() {
			this.getUnarchivedChats({});
			this.getArchivedChats({});
		},

		// getMessageListOlder({chatId, lastMessageDate}) {
		// 	socketService.emit('getMessageOlders', chatId, lastMessageDate);
		// },
		getArchivedChats({page = 1}) {
			socketService.emit('getArchivedChats', {page, limit: 25});
		},
		getUnarchivedChats({page = 1}) {
			socketService.emit('getUnarchivedChats', {page, limit: 25});
		},
		getChatDetail({chatId}) {
			socketService.emit('getMyChatById', chatId);
		},
		getUserOnlineStatus({username}) {
			socketService.emit('userIsOnlineCheck', username);
		},
		archiveChatById({chatId}) {
			socketService.emit('archiveChat', {chatID: chatId});
		},
		unarchiveChatById({chatId}) {
			socketService.emit('unarchiveChat', {chatID: chatId});
		},
		getUserNoteById({userId}) {
			socketService.emit('getNote.v1', {userId: userId});
		},
		saveUserNote({userId, note}) {
			socketService.emit('sendNote.v1', {userId, message: note});
		},
		seenMessages({chatId, username}) {
			socketService.emit('seenMessages', chatId, username);
		},
		sendMessage({message, toUser, fromUser, _}) {
			socketService.emit(
				'sendMessage',
				message,
				toUser.username,
				fromUser.username,
				toUser.userId,
				fromUser.userId,
				toUser.avatar,
			);
		},
		sendTyping({chatId, username}) {
			socketService.emit('typinguser', chatId, username);
		},
	};
};

export const createChatService = ({
	socketService,
	userStore,
	chatStore,
	chatListStore,
}) => {
	const eventService = createEventService({
		userStore,
		chatStore,
		chatListStore,
		socketService,
	});

	return {
		...eventService,
		userBannedCallback: null,
		listenAllListeners() {
			socketService.on('IamBanned', () => {
				if (this.userBannedCallback === null) {
					return;
				}
				this.userBannedCallback();
			});
			socketService.on('user_banned', () => {
				if (this.userBannedCallback === null) {
					return;
				}
				this.userBannedCallback();
			});
			socketService.on('receiveUnarchivedChats', (...args) =>
				eventService.receiveUnarchivedChats(...args),
			);
			socketService.on('receiveArchivedChats', (...args) =>
				eventService.receiveArchivedChats(...args),
			);
			// socketService.on('receiveMessageDetails', (...args) =>
			// 	eventService.receiveMessageDetails(...args),
			// );
			socketService.on('getMyChatByIdResult', (...args) =>
				eventService.receiveChatDetail(...args),
			);
			socketService.on('UserIsOnlineStatus', (...args) =>
				eventService.receiveUserOnlineStatus(...args),
			);
			socketService.on('receiveMessageOlders', (...args) =>
				eventService.receiveMessageOlder(...args),
			);
			socketService.on('sendNoteResult.v1', (...args) =>
				eventService.receiveSendNoteResult(...args),
			);
			socketService.on('getNoteResult.v1', (...args) =>
				eventService.receiveGetNoteResult(...args),
			);
			socketService.on('typingToYou', (...args) =>
				eventService.onTyping(...args),
			);
			socketService.on('archiveChatResult', (...args) =>
				eventService.archiveChatResult(...args),
			);
			socketService.on('unarchiveChatResult', (...args) =>
				eventService.unarchiveChatResult(...args),
			);
			socketService.on('MsgValidationError', (...args) => {
				eventService.onMsgValidationError(...args);
			});
		},
		onChatDetailMounted({chatId}) {
			eventService.getChatDetail({chatId});
		},
		onChatListMounted() {
			eventService.getAllChats();
		},
		onMounted({chatId}) {
			this.listenAllListeners();
			if (chatId) {
				this.onChatDetailMounted({chatId});
			}
			this.onChatListMounted();
		},
	};
};

