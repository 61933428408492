import moment from "moment/min/moment-with-locales"
moment.locale("tr");
const useMoment = () => {
    return moment;
}
const plugin = {
    install: (app) => {
        app.config.globalProperties.$moment = moment;
    }
}
export {
    plugin as default,
    useMoment
}
