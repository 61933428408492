import IsEmoji from "@/components/emoji-pickers/EmojiPickerWrapper.vue";
import IsSpin from "@/components/spin/IsSpin.vue";
import VerificationModal from "@/components/modal/verification/VerificationModal.vue";
import { InputMask } from "@/directives/InputMask";
import { useApi } from "@/config/api";
import UserBox from "@/components/box/UserBox.vue";
import Marquee from "@/components/marquee/Marquee.vue";

export default {
  install: (app) => {
    app.component("is-emoji-picker", IsEmoji);
    app.component("is-spin", IsSpin);
    app.component("is-verification-modal", VerificationModal);
    app.component("is-user-box", UserBox);
    app.component("is-marquee", Marquee);
    app.directive("is-mask", InputMask);
    const api = useApi();
    app.config.globalProperties.$cdnUrl = api.cdnUrl;
    app.config.globalProperties.$cdnAvatarUrl = api.cdnAvatarUrl;
  },
};
