import { createRouter, createWebHistory } from "vue-router";
import { requiredAuthMiddleware } from "./router.middleware";
import authRoutes from "./auth.routes";
import chatRoutes from "./chat.routes";

const routes = [...authRoutes, ...chatRoutes];

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(requiredAuthMiddleware);
