const api = {
  cdnUrl: "https://cdn.itemsatis.com/",
  cdnAvatarUrl: "https://cdn.itemsatis.com/avatar/",
  recaptchaV3: {
    siteKey: "6LfIfZEdAAAAANcxbLH8izj18olFscf20nu_zOju",
  },
  recaptchaV2: {
    siteKey: "6LeJFPwgAAAAAOpzQ44EhVNme2hlYrd0enSsdF9Q",
  },
};

export const useApi = () => {
  return api;
};
