<script setup>
import { Picker } from "emoji-picker-element";
import { onMounted, ref } from "vue";
import tr from "emoji-picker-element/i18n/tr.js";

const emit = defineEmits(["is:click"]);

const container = ref(null);

const useListeners = (picker) => {
  picker.addEventListener("emoji-click", (event) => {
    emit("is:click", event);
  });
};

const picker = new Picker(tr);

const usePicker = () => {
  container.value.append(picker);
  useListeners(picker);
  useCss(picker);
};

const useCss = (picker) => {
  const style = document.createElement("style");
  style.textContent = `.picker{border-radius: 10px; padding: 10px 5px; border-bottom-right-radius: 2px;}`;
  picker.shadowRoot.appendChild(style);
};

onMounted(() => {
  usePicker();
});
</script>

<template>
  <div ref="container" class="light"></div>
</template>

<style lang="scss">
emoji-picker {
  --outline-size: 0;
  --input-font-size: 0.9rem;
  --indicator-height: 1.5px;
  --emoji-size: 1rem;
  --emoji-padding: 0.3rem;
  --num-columns: 6;
}
</style>
