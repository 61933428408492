import { createApp } from "vue";
import { createPinia } from "pinia";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { router } from "./router";
import firebaseMessaging from "./firebase";
import moment from "@/plugins/moment";
import antDesign from "@/plugins/antDesign";
import itemsatis from "@/plugins/itemsatis";
import App from "./App.vue";
import { useApi } from "./config/api";
import "emoji-picker-element";
import "./styles/global.scss";
import "./registerServiceWorker";

const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(itemsatis);
app.use(antDesign);
app.use(moment);
app.use(VueReCaptcha, { siteKey: useApi().recaptchaV3.siteKey });
app.config.globalProperties.$messaging = firebaseMessaging;
app.mount("#app");
