export default [
  {
    name: "auth-view",
    path: "/",
    components: {
      default: () => import("@/layout/auth/AuthLayout.vue"),
    },
    children: [
      {
        name: "login-view",
        path: "",
        components: {
          default: () => import("@/views/auth/LoginView.vue"),
        },
        meta: {
          title: "Giriş Yap",
          base: "auth",
        },
      },
    ],
  },
];
