<script setup>
import EmojiPicker from "./EmojiPicker.vue";
import { ref } from "vue";

const visible = ref(false);

defineEmits(["is:click"]);
</script>

<template>
  <span class="smile-icon">
    <template v-if="visible">
      <emoji-picker @is:click="$emit('is:click', $event)"></emoji-picker>
      <div class="emoji-picker-back-shadow" @click="visible = false"></div>
    </template>
    <i class="bx bx-smile font-size-150 icon-color" @click="visible = true"></i>
  </span>
</template>

<style lang="scss">
.smile-icon {
  padding: 0.5rem;
  position: relative;
  display: flex;
  emoji-picker {
    z-index: 99999;
    position: absolute;
    max-height: 250px;
    max-width: 350px;
    min-width: 250px;
    min-height: 250px;
    width: 100%;
    bottom: 2.45rem;
    left: 1.7rem;
  }
  .emoji-picker-back-shadow {
    width: 100%;
    height: 100%;
    background: var(--theme-shadow-color-600);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
}
</style>
