<script setup>
import { defineAsyncComponent, reactive, onMounted } from "vue";
import tr from "ant-design-vue/es/locale/tr_TR";

const IsFirebaseContext = defineAsyncComponent(() =>
  import("@/features/firebase/IsFirebaseContext.vue")
);

const OfflineView = defineAsyncComponent(() =>
  import("@/views/offline/OfflineView.vue")
);

const LoadingView = defineAsyncComponent(() =>
  import("@/views/loading/LoadingView.vue")
);

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  online: true,
  timerLoading: false,
});

const onOnline = () => {
  state.online = true;
};

const onOffline = () => {
  state.online = false;
};

const initNetworkListeners = () => {
  window.addEventListener("online", onOnline);
  window.addEventListener("offline", onOffline);
};

const startDefaultLoading = () => {
  state.timerLoading = true;
  setTimeout(() => {
    state.timerLoading = false;
  }, 1500);
};

onMounted(() => {
  initNetworkListeners();
  startDefaultLoading();
});
</script>

<template>
  <div class="default-layout">
    <a-config-provider :locale="tr">
      <is-firebase-context></is-firebase-context>
      <loading-view v-if="loading || state.timerLoading"></loading-view>
      <router-view v-else-if="state.online"></router-view>
      <offline-view v-else></offline-view>
    </a-config-provider>
  </div>
</template>

<style lang="scss">
.default-layout {
  background: var(--primary-background-with-image);
}
</style>
