import { defineStore } from "pinia";

const defaultUser = {
  Id: null,
  UserName: null,
  MailAddress: null,
  Avatar: null,
  IdentityVerify: false,
  MailVerify: false,
  PhoneVerify: null,
  Approved: null,
};

export const useUserStore = defineStore("user", {
  state: () => ({
    user: JSON.parse(
      localStorage.getItem("user") || JSON.stringify(defaultUser)
    ),
  }),
  getters: {
    getUser: (state) => state.user,
    getUserId: (state) => +state.user.Id,
    getUserName: (state) => state.user.UserName,
    getMailAddress: (state) => state.user.MailAddress,
    getAvatar: (state) => state.user.Avatar,
    getIdentityVerify: (state) => state.user.IdentityVerify,
    getMailVerify: (state) => state.user.MailVerify,
    getPhoneVerify: (state) => Boolean(+state.user.PhoneVerify),
    getApproved: (state) => state.user.Approved,
  },
  actions: {
    setUser(user) {
      this.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    clear() {
      localStorage.removeItem("user");
      this.$reset();
    },
  },
});
