import { defineStore } from "pinia";

const AuthKeys = {
  socketToken: "auth:socketToken",
  token: "auth:token",
  fcmToken: "auth:fcmToken",
};

export const useAuthStore = defineStore("auth", {
  state: () => ({
    isAuthenticated: false,
    socketToken: localStorage.getItem(AuthKeys.socketToken) || "",
    token: localStorage.getItem(AuthKeys.token) || "",
    fcmToken: localStorage.getItem(AuthKeys.fcmToken) || "",
  }),
  getters: {
    getIsAuthenticated: (state) => state.isAuthenticated,
    getSocketToken: (state) => state.socketToken,
    getToken: (state) => state.token,
    getFcmToken: (state) => state.fcmToken,
  },
  actions: {
    setIsAuthenticated(authenticated) {
      this.isAuthenticated = authenticated;
    },
    setSocketToken(token) {
      this.socketToken = token;
      localStorage.setItem(AuthKeys.socketToken, token);
    },
    setToken(token) {
      this.token = token;
      localStorage.setItem(AuthKeys.token, token);
    },
    setFcmToken(token) {
      this.fcmToken = token;
      localStorage.setItem(AuthKeys.fcmToken, token);
    },
    clear() {
      localStorage.removeItem(AuthKeys.socketToken);
      localStorage.removeItem(AuthKeys.token);
      localStorage.removeItem(AuthKeys.fcmToken);
      this.$reset();
    },
  },
});
