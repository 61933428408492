import { io } from "socket.io-client";

export const createSocketService = () => {
  const listenerQueue = new Set();
  const publisherQueue = new Set();
  const listeners = new Map();
  return {
    listenerQueue: listenerQueue,
    publisherQueue: publisherQueue,
    listeners: listeners,
    url: "wss://chat.itemsatis.com",
    socket: null,
    token: null,
    setToken(token) {
      this.token = token;
    },
    get connected() {
      return this.socket && this.socket.connected;
    },
    connect() {
      this.socket = io(this.url, {
        transports: ["websocket", "polling"],
        query: {
          userData: this.token,
        },
      });
      this.socket.on("connect", () => {
        this.onConnect();
      });
    },
    on(eventName, callback, unique = true) {
      if (this.connected) {
        this.socket.on(eventName, callback);
        if (unique) {
          if (this.listeners.has(eventName)) return;
          this.listeners.set(eventName, callback);
        }
      } else {
        this.listenerQueue.add({ eventName, callback });
      }
    },
    emit(eventName, ...args) {
      if (this.connected) {
        this.socket.emit(eventName, ...args);
      } else {
        this.publisherQueue.add({ eventName, args });
      }
    },
    deployAllListeners() {
      for (const listener of this.listenerQueue) {
        this.on(listener.eventName, listener.callback);
        this.listenerQueue.delete(listener);
      }
    },
    deployAllPublishers() {
      for (const publisher of this.publisherQueue) {
        this.emit(publisher.eventName, ...publisher.args);
        this.publisherQueue.delete(publisher);
      }
    },
    onConnect() {
      this.deployAllListeners();
      this.deployAllPublishers();
    },
  };
};
