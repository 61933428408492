import { defineStore } from "pinia";
import { useUserStore } from "./user.store";
import { debounce } from "@/utility/utility";
import { useChatStore } from "./chat.store";

const ChatKeys = {
  archived: "cache:chat:archived",
  archivedPage: "cache:chat:archived-page",
  unarchivedPage: "cache:chat:unarchived-page",
  unarchived: "cache:chat:unarchived",
  chatList: "cache:chat:list",
  allChatList: "cache:chat:allList",
  lastRequestTime: "cache:chat:lastRequestTime",
};

const reverseChat = (chat) => {
  let temp = { ...chat };
  chat.receiverID = temp.senderID;
  chat.receiverName = temp.senderName;
  chat.receiverAvatar = temp.senderAvatar;
  chat.senderID = temp.receiverID;
  chat.senderName = temp.receiverName;
  chat.senderAvatar = temp.receiverAvatar;
};

// sort array by date
const sortByDate = (a, b) =>
  new Date(Date.parse(b.Datetime)) - new Date(Date.parse(a.Datetime));

const setReceiverSeenCheck = (chat) => {
  const chatDetailStore = useChatStore();
  if (chatDetailStore.getCurrentChat.chatID !== chat.chatID) {
    chat.receiverSeen = false;
  }
};

export const useChatListStore = defineStore("chatList", {
  state: () => ({
    archivedPage: +(localStorage.getItem(ChatKeys.archivedPage) || "0"),
    unarchivedPage: +(localStorage.getItem(ChatKeys.unarchivedPage) || "0"),
    archivedChats: JSON.parse(localStorage.getItem(ChatKeys.archived) || "[]"),
    unarchivedChats: JSON.parse(
      localStorage.getItem(ChatKeys.unarchived) || "[]"
    ),
    chats: JSON.parse(localStorage.getItem(ChatKeys.chatList) || "[]"),
    allChats: JSON.parse(localStorage.getItem(ChatKeys.allChatList) || "{}"),
    lastRequestTime: +(localStorage.getItem(ChatKeys.lastRequestTime) || "0"),
    isArchived: false,
    typingChats: [],
  }),
  getters: {
    getArchivedChats: (state) => state.archivedChats,
    getUnarchivedChats: (state) => state.unarchivedChats,
    getArchivedPage: (state) => state.archivedPage,
    getUnarchivedPage: (state) => state.unarchivedPage,
    getChats: (state) => state.chats,
    getAllChats: (state) => state.allChats,
    getLastRequestTime: (state) => state.lastRequestTime,
    getTypingChats: (state) => state.typingChats,
    getIsArchived: (state) => state.isArchived,
  },
  actions: {
    archiveChat({ chatID }) {
      const chat = this.unarchivedChats.find((chat) => chat.chatID === chatID);
      if (chat) {
        const userStore = useUserStore();
        chat.archivers.push(userStore.getUserId);
        this.unarchivedChats = this.unarchivedChats.filter(
          (chat) => chat.chatID !== chatID
        );
        this.archivedChats.push(chat);
        this.sortArchivedChats();
        this.sortUnarchivedChats();
        this.setIsArchived(true);
      }
    },
    unarchiveChat({ chatID }) {
      const chat = this.archivedChats.find((chat) => chat.chatID === chatID);
      if (chat) {
        const userStore = useUserStore();
        chat.archivers = chat.archivers.filter(
          (archiver) => archiver !== userStore.getUserId
        );
        this.archivedChats = this.archivedChats.filter(
          (chat) => chat.chatID !== chatID
        );
        this.unarchivedChats.push(chat);
        this.sortArchivedChats();
        this.sortUnarchivedChats();
        this.setIsArchived(false);
      }
    },
    setArchivedPage(page) {
      this.archivedPage = page;
      localStorage.setItem(ChatKeys.archivedPage, page);
    },
    setUnarchivedPage(page) {
      this.unarchivedPage = page;
      localStorage.setItem(ChatKeys.unarchivedPage, page);
    },
    setIsArchived(isArchived) {
      this.isArchived = isArchived;
    },
    sortArchivedChats() {
      this.archivedChats.sort(sortByDate);
    },
    /* Sorting the chats by date. */
    sortUnarchivedChats() {
      this.unarchivedChats.sort(sortByDate);
    },
    setUnarchivedChats({ chats, page }) {
      if (page === 1) {
        this.unarchivedChats = [...new Set(chats)]; // for remove duplicate
      } else {
        const arr = [...this.unarchivedChats, ...chats];
        this.unarchivedChats = [...new Set(arr)];
      }

      this.setUnarchivedPage(page + 1);
      localStorage.setItem(
        ChatKeys.unarchived,
        JSON.stringify(this.unarchivedChats)
      );
      this.sortUnarchivedChats();
    },
    setArchivedChats({ chats, page }) {
      if (page === 1) {
        this.archivedChats = [...new Set(chats)]; // for remove duplicate
      } else {
        const arr = [...this.archivedChats, ...chats];
        this.archivedChats = [...new Set(arr)];
      }
      this.setArchivedPage(page + 1);
      localStorage.setItem(
        ChatKeys.archived,
        JSON.stringify(this.archivedChats)
      );
      this.sortArchivedChats();
    },
    addMessage({ chatId, message, senderId, date }) {
      let chat = this.archivedChats.find((chat) => chat.chatID === chatId);
      if (!chat) {
        chat = this.unarchivedChats.find((chat) => chat.chatID === chatId);
      }
      if (!chat) return;
      chat.Message = message;
      chat.Datetime = date;
      if (chat.senderID !== senderId) {
        reverseChat(chat);
      }
      setReceiverSeenCheck(chat);
      this.sortArchivedChats();
      this.sortUnarchivedChats();
    },
    receiveMessage({ chatId, message, senderName, avatar, senderId }) {
      const userStore = useUserStore();
      let chat = this.archivedChats.find((chat) => chat.chatID === chatId);
      if (!chat) {
        chat = this.unarchivedChats.find((chat) => chat.chatID === chatId);
      }
      if (!chat) {
        chat = {
          chatID: chatId,
          Message: message,
          Datetime: new Date(),
          senderName: senderName,
          senderAvatar: avatar,
          senderID: senderId,
          receiverID: userStore.getUserId,
          receiverName: userStore.getUserName,
          receiverAvatar: userStore.getAvatar,
          receiverSeen: false,
        };
        this.unarchivedChats.push(chat);
      }
      chat.Message = message;
      chat.Datetime = new Date();
      if (chat.senderID !== senderId) {
        reverseChat(chat);
      }
      setReceiverSeenCheck(chat);
      this.sortArchivedChats();
      this.sortUnarchivedChats();
    },
    setChats(chats) {
      this.chats = chats;
      localStorage.setItem(ChatKeys.chatList, JSON.stringify(chats));
    },
    addChats(chats) {
      this.chats = [...this.chats, ...chats];
      localStorage.setItem(ChatKeys.chatList, JSON.stringify(this.chats));
    },
    addTypingChat({ chatId }) {
      if (!this.typingChats.includes(chatId)) {
        this.typingChats.push(chatId);
      }
      this.debouncedRemoveTypingChat(this.removeTypingChat, { chatId });
    },
    debouncedRemoveTypingChat: debounce((func, params) => {
      func(params);
    }, 2000),
    removeTypingChat({ chatId }) {
      this.typingChats = this.typingChats.filter((c) => c !== chatId);
    },
    setAllChats(chats) {
      let allChats = {};
      if (Array.isArray(chats)) {
        chats.forEach((chat) => {
          allChats[chat.ChatId] = chat;
        });
      } else if (typeof chats === "object") {
        allChats = chats;
      }
      this.allChats = allChats;
      localStorage.setItem(ChatKeys.allChatList, JSON.stringify(allChats));
    },
    updateChatInAllChats(chat) {
      this.allChats[chat.ChatId] = chat;
      localStorage.setItem(ChatKeys.allChatList, JSON.stringify(this.allChats));
    },
    setLastRequestTime() {
      this.lastRequestTime = Math.floor(Date.now() / 1000);
      localStorage.setItem(ChatKeys.lastRequestTime, this.lastRequestTime);
    },
    onTypingStateChange(chat) {
      const newList = [...this.chats];
      const index = this.chats.findIndex((c) => c.chatID === chat.chatID);
      newList[index] = {
        ...newList[index],
        typing: chat.typing,
      };
      this.chats = newList;
    },
    clear() {
      Object.keys(ChatKeys).forEach((key) => {
        localStorage.removeItem(key);
      });
      this.$reset();
    },
  },
});
