export default [
  {
    name: "chat-view",
    path: "/chats",
    components: {
      default: () => import("@/layout/chat/ChatLayout.vue"),
    },
    children: [
      {
        name: "chat-empty-view",
        path: "",
        components: {
          default: () => import("@/views/chat/empty/ChatEmptyView.vue"),
          sideMenu: () => import("@/layout/chat/sideMenu/ChatSideMenu.vue"),
          chatList: () => import("@/views/chat/list/ChatListView.vue"),
        },
      },
      {
        name: "chat-detail-view",
        path: ":id",
        components: {
          sideMenu: () => import("@/layout/chat/sideMenu/ChatSideMenu.vue"),
          chatList: () => import("@/views/chat/list/ChatListView.vue"),
          default: () => import("@/views/chat/detail/ChatDetailView.vue"),
        },
        meta: {
          chatDetail: true,
        },
        children: [
          {
            name: "chat-detail-user-note-view",
            path: "notes",
            components: {
              sidebar: () =>
                import("@/layout/chat/detail/ChatDetailUserNoteSidebar.vue"),
            },
            meta: {
              sidebar: true,
            },
          },
          {
            name: "chat-detail-user-order-view",
            path: "orders",
            components: {
              sidebar: () =>
                import("@/layout/chat/detail/ChatDetailUserOrderSidebar.vue"),
            },
            meta: {
              sidebar: true,
            },
          },
        ],
      },
    ],
  },
];
