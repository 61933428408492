<script setup>
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { ref, reactive, computed, onMounted, provide } from "vue";
import moment from "moment";
import { useHttps } from "@/plugins/http";
import { message } from "ant-design-vue";
import { createSocketService } from "./services/socket/socket.service";
import { createChatService } from "./services/socket/chat.service";
import { useRouter } from "vue-router";
import { useChatListStore } from "./stores/chat-list.store";
import { useChatStore } from "./stores/chat.store";
import { useAuthStore } from "@/stores/auth.store";
import { useUserStore } from "@/stores/user.store";

const displayWidth = ref(window.innerWidth);

const authStore = useAuthStore();
const userStore = useUserStore();
const chatStore = useChatStore();
const chatListStore = useChatListStore();
const router = useRouter();
const https = useHttps();

const socketService = createSocketService();
const chatService = createChatService({
  socketService: socketService,
  userStore,
  chatStore,
  chatListStore,
});

const state = reactive({
  loading: false,
});

const auth = computed(() => ({
  token: authStore.getToken,
  fcmToken: authStore.getFcmToken,
  isAuthenticated: authStore.getIsAuthenticated,
  socketToken: authStore.getSocketToken,
}));

const checkAuth = async () => {
  let params = null;
  if (!!auth.value.token && auth.value.token !== "") {
    params = "token=" + auth.value.token;
  }
  if (params === null) {
    logout({ quiet: true });
    router.replace("/");
    return;
  }
  if (!!auth.value.fcmToken && auth.value.fcmToken !== "") {
    params += "&fcmToken=" + auth.value.fcmToken;
  }
  state.loading = true;
  const response = await https.post("/checkLogin", params);
  state.loading = false;
  if (response && response.data && response.data.success) {
    onLoginEvent(response.data);
    if (router.options.history.state.back === null) router.replace("/chats");
  } else {
    message.error(response.data.message);
    logout({ quiet: true });
    router.replace("/");
  }
};

const connectToSocket = () => {
  socketService.setToken(auth.value.socketToken);
  socketService.connect();
};

const getLogDatetime = (date) => {
  const logDate = moment(new Date(Date.parse(date)), "YYYY-MM-DD hh:mm:ss");
  const log_date = logDate.format("HH:mm");
  const log_date_days = logDate.format("DD.MM.YYYY");
  logDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const now = moment();
  const day = now.diff(logDate, "day");
  const hour = now.diff(logDate, "hour");
  if (day === 0) {
    if (hour < 24) {
      return `${log_date}`;
    }
  }
  if (day <= 1) {
    return `Dün`;
  }
  return `${log_date_days}`;
};
const getMessageDatetime = (date) => {
  const logDate = moment(new Date(Date.parse(date)), "YYYY-MM-DD hh:mm:ss");
  const log_date = logDate.format("HH:mm");
  const now = moment();
  return `${log_date}`;
};
const getMessageDay = (date) => {
  const logDate = moment(new Date(Date.parse(date)), "YYYY-MM-DD hh:mm:ss");
  const log_date_days = logDate.format("DD.MM.YYYY");
  logDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const now = moment();
  const day = now.diff(logDate, "day");
  if (day === 0) return `Bugün`;
  if (day === 1) return `Dün`;
  if (day === 2) return `Evvelsi gün`;
  return `${log_date_days}`;
};
const getNotesTime = (date) => {
  const logDate = moment(new Date(Date.parse(date)), "YYYY-MM-DD hh:mm:ss");
  const logDate_notes = logDate.format("DD/MM/YYYY HH:mm");
  const logDate_notes_today = logDate.format("HH:mm");
  logDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const now = moment();
  const day = now.diff(logDate, "day");
  const hour = now.diff(logDate, "hour");
  if (day === 0) {
    return `Bugün ${logDate_notes_today}`;
  } else if (day === 1) {
    return `Dün ${logDate_notes_today}`;
  }
  return `${logDate_notes}`;
};

const onResize = () => {
  displayWidth.value = window.innerWidth;
};

const listenResize = () => {
  window.addEventListener(
    "resize",
    () => {
      onResize();
    },
    false
  );
};

const logout = ({ quiet = false }) => {
  onLogoutEvent();
  router.push("/");
  if (!quiet) {
    message.success("Başarıyla çıkış yapıldı.");
  }
};

const removeAllCaches = () => {
  // check localStorage all items is includes cache and delete it
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.includes("cache")) {
      localStorage.removeItem(key);
    }
  }
};

const onLoginEvent = (response) => {
  authStore.setIsAuthenticated(true);
  authStore.setToken(response.token);
  authStore.setSocketToken(response.SocketToken);
  userStore.setUser(response.result);
  connectToSocket();
};

const onLogoutEvent = () => {
  removeAllCaches();
  authStore.setIsAuthenticated(false);
  authStore.clear();
  userStore.clear();
  chatStore.clear();
  chatListStore.clear();
};

provide("getNotesTime", getNotesTime);
provide("getMessageDay", getMessageDay);
provide("getLogDatetime", getLogDatetime);
provide("getMessageDatetime", getMessageDatetime);
provide("logout", logout);
provide("displayWidth", displayWidth);
provide("onLoginEvent", onLoginEvent);
provide("chatService", chatService);
provide("socketService", socketService);

onMounted(() => {
  chatService.userBannedCallback = () => {
    logout({ quiet: true });
    router.replace("/");
  };
  listenResize();
  checkAuth();
});
</script>

<template>
  <default-layout :loading="state.loading"></default-layout>
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
</style>
