import "ant-design-vue/dist/antd.css";
import {
  Button,
  Col,
  Collapse,
  Alert,
  Dropdown,
  Input,
  Layout,
  Menu,
  Modal,
  Row,
  Tooltip,
  message,
  Spin,
  Skeleton,
  Popconfirm,
  Empty,
  Avatar,
  Form,
  InputNumber,
  Space,
  ConfigProvider,
  Timeline,
} from "ant-design-vue";

export default {
  install: (app) => {
    app.config.globalProperties.$message = message;
    app.use(Alert);
    app.use(Row);
    app.use(Col);
    app.use(Layout);
    app.use(Tooltip);
    app.use(Input);
    app.use(InputNumber);
    app.use(Button);
    app.use(Modal);
    app.use(Collapse);
    app.use(Menu);
    app.use(Dropdown);
    app.use(Spin);
    app.use(Skeleton);
    app.use(Popconfirm);
    app.use(Empty);
    app.use(Avatar);
    app.use(Form);
    app.use(Space);
    app.use(Timeline);
    app.use(ConfigProvider);
  },
};
